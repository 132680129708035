const BLOG_IMAGE_SIZE = {
    width: 300,
    height: 174,
};

export const POSTS_CONTENT = [
    {
        image: '3382db696a31282bbfe76ab007237bc0-1649916168602/7%20types%20of%20logos-min.jpg',
        title: 'The 7 types of logos & which is right for your brand',
        alt: 'The 7 types of logos which is right for your brand',
        excerpt:
            'When it comes to creating a business logo, there are 7 types of logos to consider. Learn how to choose the right type of logo for your brand.',
        link: '/resources/guides/graphic-design/types-of-logos',
        size: BLOG_IMAGE_SIZE,
    },
    {
        image: '6f05fb897084d76c87f86da4aece11b8-1649665467147/choosing%20brand%20colors-min.jpg',
        title: 'The ultimate guide to choosing the right colors for your brand',
        alt: 'choosing the right colors for your brand',
        excerpt:
            'Learn all you need to know for choosing the right colors for your brand and how to choose your colors strategically to communicate the message you want to deliver to your audience.',
        link: '/resources/guides/graphic-design/how-to-choose-colors-for-brand',
        size: BLOG_IMAGE_SIZE,
    },
    {
        image: '74773bd8aab051ef8a227380dc4abfc9-1651413395712/how%20to%20chhose%20a%20logo%20font.jpg',
        title: 'The 4 main font types & how to choose the right logo font',
        alt: 'how to choose the right logo font',
        excerpt:
            'Learn the four main font types and how to strategically choose a logo font that will reflect your brand identity and maximize your brand awareness.',
        link: '/resources/guides/graphic-design/how-to-choose-font-for-logo',
        size: BLOG_IMAGE_SIZE,
    },
    {
        image: '10f680cb84a2f3ef4473ecfdede3a1ba-1593438129320/business%20logo%20design-fiverr%20guide.jpg',
        title: 'How to design a logo: 12 steps to creating a business logo from scratch',
        alt: 'How to design a business logo',
        excerpt:
            'Learn how to create a logo for your business with this practical guide that walks you through the key steps to design a logo you love.',
        link: '/resources/guides/graphic-design/how-to-create-business-logo',
        size: BLOG_IMAGE_SIZE,
    },
];
