import React from 'react';
import { I18n } from '@fiverr-private/i18n-react';
import LandingPageStepSection from '../../LandingPageStepSection';
import { INTERVAL, STEPS } from './constants';
import classes from './index.module.scss';

const HowToCreateStandOutLogo = () => (
    <section className={classes.wrapper}>
        <div className={classes.thinContainer}>
            <h2 className={classes.title}>
                <I18n k="makers_experience_perseus.buyer_landing_page.how_to_create_stand_out_logo.section_title" />
            </h2>
            <p className={classes.subtitle}>
                <I18n k="makers_experience_perseus.buyer_landing_page.how_to_create_stand_out_logo.section_description_1" />
            </p>
            <p className={classes.subtitle}>
                <I18n k="makers_experience_perseus.buyer_landing_page.how_to_create_stand_out_logo.section_description_2" />
            </p>
        </div>
        <div className={classes.container}>
            <LandingPageStepSection steps={STEPS} interval={INTERVAL} />
        </div>
    </section>
);

export default HowToCreateStandOutLogo;
