import React from 'react';
import cn from 'classnames';

import { Icon } from '@fiverr-private/fit';
import { s_play } from '@fiverr-private/icons';
import CloudinaryResponsiveImage from '../CloudinaryResponsiveImage';
import useVimeoVideo from './hooks/useVimeoVideo';
import classes from './index.module.scss';
import { getCloudinaryImages } from './utils/getCloudinaryImages';

const VimeoVideo = ({ video, preview, previewAlt, previewSize = {} }) => {
    const { containerRef, showPreview, handlePreviewClicked } = useVimeoVideo();

    return (
        <div className={classes.wrapper}>
            <div
                className={cn(classes.preview, {
                    [classes.hidden]: !showPreview,
                })}
                onClick={handlePreviewClicked}
            >
                <CloudinaryResponsiveImage
                    alt={previewAlt}
                    image={preview}
                    width={previewSize.width}
                    height={previewSize.height}
                    className={classes.previewImage}
                    mediaQueryGrid={getCloudinaryImages(preview)}
                />
                <div className={classes.previewBtn} role="button">
                    <Icon className={classes.previewBtnIcon}>{s_play}</Icon>
                </div>
            </div>
            <div
                ref={containerRef}
                className={classes.container}
                data-vimeo-id={video}
            />
        </div>
    );
};

export default VimeoVideo;
