import React from 'react';
import { I18n, translate } from '@fiverr-private/i18n-react';
import { Button, Input } from '@fiverr-private/fit';
import { useInputHandler } from '../../../../hooks';
import { useBriefIdContext } from '../../../BriefIdContext';
import { getLogoNameBriefStepHref } from '../../../../utils/getLogoNameBriefStepHref';
import classes from './index.module.scss';

const BottomBanner = ({ onBrandNameSubmit, source }) => {
    const briefId = useBriefIdContext();

    const { inputRef, onSubmit, onEnter } = useInputHandler({
        handleSubmit: (brandName) => {
            onBrandNameSubmit(brandName);
            window.location = getLogoNameBriefStepHref({
                source,
                brandName,
                briefId,
            });
        },
    });

    return (
        <section className={classes.wrapper}>
            <div className={classes.container}>
                <h2 className={classes.title}>
                    <I18n k="makers_experience_perseus.general.fiverr_logo_maker" />
                </h2>
                <p className={classes.subtitle}>
                    <I18n k="makers_experience_perseus.buyer_landing_page.bottom_banner.section_subtitle" />
                </p>
                <Input
                    className={classes.input}
                    inputClassName={classes.innerInput}
                    placeholder={translate(
                        'makers_experience_perseus.buyer_landing_page.bottom_banner.placeholder'
                    )}
                    onKeyPress={onEnter}
                    ref={inputRef}
                    aria-label={translate(
                        'makers_experience_perseus.buyer_landing_page.bottom_banner.placeholder'
                    )}
                />
                <Button
                    onClick={onSubmit}
                    className={classes.button}
                    size={Button.SIZES.LARGE}
                    fill={Button.FILLS.FULL}
                    color={Button.COLORS.WHITE}
                    textColor={Button.TEXT_COLORS.DARK_GREY}
                >
                    <I18n k="makers_experience_perseus.general.make_your_logo" />
                </Button>
            </div>
        </section>
    );
};

export default BottomBanner;
