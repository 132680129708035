const { addDomainPrefix } = require('../../shared/utils/addDomainPrefix');

const APP_NAME = 'buyer';
const DOMAIN_APP_NAME = addDomainPrefix(APP_NAME);
const ROUTE_BASE_PATH = '/logo-maker/listing';

module.exports = {
    APP_NAME,
    DOMAIN_APP_NAME,
    ROUTE_BASE_PATH,
};
