import React from 'react';
import TotalRating from '../../TotalRating';
import classes from './index.module.scss';

/**
 * @param {{
 *  rating?: number,
 *  ratingsCount?: number;
 * }} props
 */
const TotalRatingSection = ({ rating, ratingsCount }) => (
    <section className={classes.wrapper}>
        <div className={classes.container}>
            <TotalRating
                rating={rating}
                ratingsCount={ratingsCount}
                className={classes.rating}
            />
        </div>
    </section>
);

export default TotalRatingSection;
