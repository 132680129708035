import { breakpoints } from '@fiverr-private/sass/helpers';

export const IMAGE_RATIO = [
    {
        media: `(min-width: ${breakpoints.lg}px)`,
        config: { width: 0.6, height: 0.6 },
    },
    {
        media: `(min-width: ${breakpoints.md}px)`,
        config: { width: 0.4, height: 0.4 },
    },
    {
        media: `(min-width: ${breakpoints.sm}px)`,
        config: { width: 0.7, height: 0.7 },
    },
];

export const EXAMPLES_NAMES = {
    BEAUTY: 'beauty',
    GAMING: 'gaming',
    PIZZERIA: 'pizzeria',
    REAL_ESTATE: 'real-estate',
};

export const EXAMPLES_IMAGES = {
    [EXAMPLES_NAMES.BEAUTY]: [
        {
            src: '82949f391311933fcbe4ef07ead5a8aa-1581023431191/logo-maker-example-beauty-part-1.png',
            size: { width: 588, height: 626 },
        },
        {
            src: '58e544427e0ad86aed89f9b6810b1c1a-1581020912245/logo-maker-example-beauty-part-2.png',
            size: { width: 875, height: 589 },
        },
        {
            src: '58e544427e0ad86aed89f9b6810b1c1a-1581020912236/logo-maker-example-beauty-part-3.png',
            size: { width: 521, height: 607 },
        },
        {
            src: '58e544427e0ad86aed89f9b6810b1c1a-1581020912250/logo-maker-example-beauty-part-4.png',
            size: { width: 271, height: 429 },
        },
    ],
    [EXAMPLES_NAMES.GAMING]: [
        {
            src: '2df93f42633a505866489e602cb6fda7-1581020945332/logo-maker-example-gaming-part-1.png',
            size: { width: 550, height: 647 },
        },
        {
            src: '2df93f42633a505866489e602cb6fda7-1581020945337/logo-maker-example-gaming-part-2.png',
            size: { width: 599, height: 458 },
        },
        {
            src: '2df93f42633a505866489e602cb6fda7-1581020945326/logo-maker-example-gaming-part-3.png',
            size: { width: 570, height: 703 },
        },
    ],
    [EXAMPLES_NAMES.PIZZERIA]: [
        {
            src: 'c642692a672dc74707365b99a2eb416a-1581020968814/logo-maker-example-pizzeria-part-1.png',
            size: { width: 306, height: 278 },
        },
        {
            src: 'c642692a672dc74707365b99a2eb416a-1581020968812/logo-maker-example-pizzeria-part-2.png',
            size: { width: 399, height: 517 },
        },
        {
            src: 'c642692a672dc74707365b99a2eb416a-1581020968802/logo-maker-example-pizzeria-part-3.png',
            size: { width: 156, height: 163 },
        },
        {
            src: 'c642692a672dc74707365b99a2eb416a-1581020968810/logo-maker-example-pizzeria-part-4.png',
            size: { width: 395, height: 452 },
        },
        {
            src: 'c642692a672dc74707365b99a2eb416a-1581020968818/logo-maker-example-pizzeria-part-5.png',
            size: { width: 197, height: 222 },
        },
        {
            src: 'c642692a672dc74707365b99a2eb416a-1581020968812/logo-maker-example-pizzeria-part-6.png',
            size: { width: 392, height: 262 },
        },
        {
            src: 'c642692a672dc74707365b99a2eb416a-1581020968803/logo-maker-example-pizzeria-part-7.png',
            size: { width: 528, height: 613 },
        },
    ],
    [EXAMPLES_NAMES.REAL_ESTATE]: [
        {
            src: 'eca07135c466945de30fb5542223b87f-1581020993313/logo-maker-example-real-estate-part-1.png',
            size: { width: 234, height: 379 },
        },
        {
            src: 'eca07135c466945de30fb5542223b87f-1581020993320/logo-maker-example-real-estate-part-2.png',
            size: { width: 471, height: 537 },
        },
        {
            src: 'eca07135c466945de30fb5542223b87f-1581020993301/logo-maker-example-real-estate-part-3.png',
            size: { width: 405, height: 254 },
        },
        {
            src: 'eca07135c466945de30fb5542223b87f-1581020993305/logo-maker-example-real-estate-part-4.png',
            size: { width: 338, height: 534 },
        },
        {
            src: 'eca07135c466945de30fb5542223b87f-1581020993305/logo-maker-example-real-estate-part-5.png',
            size: { width: 367, height: 322 },
        },
        {
            src: 'eca07135c466945de30fb5542223b87f-1581020993303/logo-maker-example-real-estate-part-6.png',
            size: { width: 471, height: 470 },
        },
    ],
};
