const { addDomainPrefix } = require('../../shared/utils/addDomainPrefix');

const APP_NAME = 'brief';
const DOMAIN_APP_NAME = addDomainPrefix(APP_NAME);
const ROUTE_BASE_PATH = '/logo-maker/brief';

module.exports = {
    APP_NAME,
    DOMAIN_APP_NAME,
    ROUTE_BASE_PATH,
};
