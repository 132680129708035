import React from 'react';
import { I18n } from '@fiverr-private/i18n-react';
import { Avatar, Icon } from '@fiverr-private/fit';
import { Rating } from '@fiverr-private/orca';
import serverRenderIf from '../../../../../utils/serverRenderIf';
import { useUserAgentContext } from '../../../../UserAgentContext';
import Quotes from './Quotes';
import classes from './index.module.scss';

const TestimonialCard = ({
    rating,
    message,
    avatar,
    author,
    organization,
    industry,
    countryIcon,
    country,
}) => {
    const { isMobile } = useUserAgentContext();

    return (
        <div className={classes.wrapper}>
            <Rating
                className={classes.rating}
                score={rating}
                scoreMinimumFractionDigits={1}
                starClassName={classes.ratingStar}
                scoreClassName={classes.ratingScore}
            />
            <div className={classes.author}>
                <Avatar
                    className={classes.avatar}
                    src={avatar}
                    size={48}
                    username={author}
                />
                <div className={classes.authorDetails}>
                    <div className={classes.authorMainDetails}>
                        <b>{author}, </b>
                        <span>{organization}</span>
                    </div>
                    {serverRenderIf(!isMobile) && (
                        <div className={classes.authorSecondaryDetails}>
                            <b>
                                <I18n k="makers_experience_perseus.buyer_landing_page.what_users_say.industry" />
                                :{' '}
                            </b>
                            <span>{industry}</span>
                            <Icon className={classes.authorCountryFlag}>
                                {countryIcon}
                            </Icon>
                            <span>{country}</span>
                        </div>
                    )}
                </div>
            </div>
            <p className={classes.message}>
                <Quotes className={classes.quotesIcon} />
                {message}
            </p>
            {serverRenderIf(isMobile) && (
                <div className={classes.industryMobileDetails}>
                    <b>
                        <I18n k="makers_experience_perseus.buyer_landing_page.what_users_say.industry" />
                        :{' '}
                    </b>
                    <span>{industry}</span>
                    <Icon className={classes.authorCountryFlag}>
                        {countryIcon}
                    </Icon>
                    <span>{country}</span>
                </div>
            )}
        </div>
    );
};

export default TestimonialCard;
