const VIDEO_CONTENT_SRC = 'https://vimeo.com/706094337';
const VIDEO_SRC = 'https://player.vimeo.com/video/706094337';
const VIDEO_THUMBNAIL =
    '0db0b2a7275cb8da46a7746043500948-1652191438915/logo-maker-introduction-video.png';
const TRANSLATION_TITLE_KEY =
    'makers_experience_perseus.buyer_landing_page.how_to_make_logo_video.section_title';
const PREVIEW_IMG_SIZE = {
    width: 1036,
    height: 583,
};

module.exports = {
    VIDEO_CONTENT_SRC,
    VIDEO_SRC,
    VIDEO_THUMBNAIL,
    TRANSLATION_TITLE_KEY,
    PREVIEW_IMG_SIZE,
};
