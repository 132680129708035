import React, { useState, useMemo } from 'react';
import classnames from 'classnames';
import noop from 'lodash/noop';
import { translate } from '@fiverr-private/i18n-react';
import {
    StarIcon as StarEmptyIcon,
    StarSolidIcon,
    HalfStarIcon,
} from '@fiverr-private/icons';
import classes from './index.module.scss';

/**
 * @param {{
 *  className?: string,
 *  rating?: number,
 *  defaultRating?: number;
 *  onChange?: (rating: number) => void,
 *  readonly?: boolean,
 * }} props
 */
const ClickableRating = ({
    className,
    rating,
    defaultRating = 0,
    onChange = noop,
    readonly = false,
}) => {
    const [hoveredHighestStar, setHoveredHighestStar] = useState(0);
    const handleStarHover = (starIndex) => () => {
        if (readonly) {
            return;
        }
        setHoveredHighestStar(starIndex + 1);
    };
    const handleStarUnhover = () => {
        setHoveredHighestStar(0);
    };

    const [innerRating, setInnerRating] = useState(rating ?? defaultRating);
    const actualRating = rating ?? innerRating;

    const stars = useMemo(
        () =>
            Array.from({ length: 5 }).map((_, i) => {
                if (hoveredHighestStar === 0 || readonly) {
                    const roundedRating = Math.round(actualRating * 2) / 2;
                    if (i <= roundedRating - 1) {
                        return StarSolidIcon;
                    }
                    if (i === roundedRating - 0.5) {
                        return HalfStarIcon;
                    }
                    return StarEmptyIcon;
                }
                if (i < hoveredHighestStar) {
                    return StarSolidIcon;
                }
                return StarEmptyIcon;
            }),
        [actualRating, hoveredHighestStar, readonly]
    );

    const handleChange = (starIndex) => () => {
        if (readonly) {
            return;
        }
        onChange(starIndex + 1);
        if (rating === undefined) {
            setInnerRating(starIndex + 1);
        }
    };

    return (
        <div className={classnames(classes.wrapper, className)}>
            <div
                className={classnames(classes.starsWrapper, {
                    [classes.readonly]: readonly,
                })}
            >
                {stars.map((StarIcon, i) => (
                    <div
                        key={i}
                        className={classes.starIcon}
                        onMouseEnter={handleStarHover(i)}
                        onMouseLeave={handleStarUnhover}
                        onClick={handleChange(i)}
                    >
                        <StarIcon />
                    </div>
                ))}
            </div>
            {hoveredHighestStar > 0 && (
                <div className={classes.labelsWrapper}>
                    <span>
                        {translate(
                            'makers_experience_perseus.general.not_satisfied'
                        )}
                    </span>
                    <span>
                        {translate(
                            'makers_experience_perseus.general.very_satisfied'
                        )}
                    </span>
                </div>
            )}
        </div>
    );
};

export default ClickableRating;
