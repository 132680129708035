import React from 'react';
import { I18n, translate } from '@fiverr-private/i18n-react';
import { VALUES_LIST } from './constants';
import classes from './index.module.scss';

const ValuePoints = () => (
    <section className={classes.wrapper}>
        <div className={classes.container}>
            <h2 className={classes.title}>
                <I18n k="makers_experience_perseus.buyer_landing_page.create_logo_value_points.section_title" />
            </h2>
            <div className={classes.values}>
                {VALUES_LIST.map(({ alt, body, title, illustration }) => (
                    <div className={classes.value} key={title}>
                        <div className={classes.illustration}>
                            <img
                                alt={translate(alt)}
                                className={classes.illustrationImage}
                                width={illustration.width}
                                height={illustration.height}
                                src={illustration.src}
                                loading="lazy"
                            />
                        </div>
                        <h3 className={classes.valueTitle}>
                            <I18n k={title} />
                        </h3>
                        <p className={classes.description}>
                            <I18n k={body} />
                        </p>
                    </div>
                ))}
            </div>
        </div>
    </section>
);

export default ValuePoints;
