const ILLUSTRATION_SIZE = {
    width: 639,
    height: 426,
};

const ILLUSTRATIONS = [
    {
        src: '59285534489d7993b16b66c1db9b228d-1652446635561/know-your-brand-personality-fiverr-logo-maker.png',
        size: ILLUSTRATION_SIZE,
    },
    {
        src: '8e5451ec2c1b34e523333e6915973e31-1652446784719/emblem_-monogram_-pictorial_-mascot_-abstract-types-of-logo-fiverr-logo-maker.png',
        size: ILLUSTRATION_SIZE,
    },
    {
        src: '5e508458c56c2103a673123a14f6b14d-1652446704174/decide-what-style-you-want-for-your-logo-fiverr-logo-maker.png',
        size: ILLUSTRATION_SIZE,
    },
    {
        src: 'b0195d67e4405fd86f70f07de3eaffe2-1652446845553/choose-logo-font-fiverr-logo-maker.png',
        size: ILLUSTRATION_SIZE,
    },
    {
        src: '7c2e5338b8914fa883561a099d6ff95f-1652446933751/choose-color-palette-fiverr-logo-maker.png',
        size: ILLUSTRATION_SIZE,
    },
];

const COPIES_SLUG =
    'makers_experience_perseus.buyer_landing_page.how_to_create_stand_out_logo';

export const STEPS = ILLUSTRATIONS.map((illustration, i) => ({
    step: `${COPIES_SLUG}.step_${i + 1}.step`,
    title: `${COPIES_SLUG}.step_${i + 1}.title`,
    description: [
        `${COPIES_SLUG}.step_${i + 1}.description_1`,
        `${COPIES_SLUG}.step_${i + 1}.description_2`,
    ],
    alt: `${COPIES_SLUG}.step_${i + 1}.alt`,
    illustration,
}));

export const INTERVAL = 7000;
