import React from 'react';
import { noop } from 'lodash';
import { I18n } from '@fiverr-private/i18n-react';
import { Slider } from '@fiverr-private/orca';
import serverRenderIf from '../../../../utils/serverRenderIf';
import { useUserAgentContext } from '../../../UserAgentContext';
import PostCard from './PostCard';
import { POSTS_CONTENT } from './constants';
import classes from './index.module.scss';

const HowToCreatePerfectLogo = ({ onPostClick = noop }) => {
    const { isMobile } = useUserAgentContext();
    return (
        <section className={classes.wrapper}>
            <div className={classes.container}>
                <h2 className={classes.title}>
                    <I18n k="makers_experience_perseus.buyer_landing_page.blog_posts.section_title" />
                </h2>
                {serverRenderIf(!isMobile) && (
                    <div className={classes.postsBox}>
                        {POSTS_CONTENT.map((post, i) => (
                            <PostCard
                                key={post.title}
                                onClick={() => onPostClick(post.title, i)}
                                {...post}
                            />
                        ))}
                    </div>
                )}
                {serverRenderIf(isMobile) && (
                    <Slider
                        className={classes.postsSlider}
                        items={POSTS_CONTENT}
                        Component={PostCard}
                        slidesToShow={1}
                        sliderBreakpoints={[]}
                    />
                )}
            </div>
        </section>
    );
};

export default HowToCreatePerfectLogo;
