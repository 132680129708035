import React, { useState } from 'react';
import { I18n } from '@fiverr-private/i18n-react';
import { Button } from '@fiverr-private/fit';
import getHeroOffset from '@fiverr-private/variations_display/src/utils/getHeroOffset';
import usePageScroll from '../../hooks/usePageScroll';
import { useBriefIdContext } from '../BriefIdContext';
import { getLogoNameBriefStepHref } from '../../utils/getLogoNameBriefStepHref';
import classes from './style.module.scss';

const MakeMyLogoButton = () => {
    const briefId = useBriefIdContext();
    const path = getLogoNameBriefStepHref({
        source: 'sticky_CTA',
        briefId,
    });

    const [pageIsScrolledDown, setPageIsScrolledDown] = useState(false);

    usePageScroll(() => {
        setPageIsScrolledDown(window.pageYOffset > getHeroOffset());
    });

    return (
        pageIsScrolledDown && (
            <Button
                href={path}
                rel="nofollow"
                color={Button.COLORS.BLACK}
                fill={Button.FILLS.FULL}
                className={classes.navLinkMakeMyLogo}
            >
                <I18n k="makers_experience_perseus.general.make_your_logo" />
            </Button>
        )
    );
};

export default MakeMyLogoButton;
