import { breakpoints } from '@fiverr-private/sass/helpers';

export const TRANSITION_DURATION = 150;
export const DEFAULT_INTERVAL = 10000;
export const RESPONSIVE_IMAGES = [
    {
        media: `(max-width: ${breakpoints.md}px)`,
        config: { width: 327 },
    },
    {
        media: `(min-width: ${breakpoints.md}px)`,
        config: { width: 668 },
    },
];
