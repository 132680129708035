const { addDomainPrefix } = require('../../shared/utils/addDomainPrefix');

const APP_NAME = 'buyer_saved_logos';
const DOMAIN_APP_NAME = addDomainPrefix(APP_NAME);
const ROUTE_BASE_PATH = '/logo-maker';
const ROUTES = {
    SAVED_LOGOS: 'saved-logos',
};

module.exports = {
    APP_NAME,
    DOMAIN_APP_NAME,
    ROUTE_BASE_PATH,
    ROUTES,
};
