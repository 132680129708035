import { useState, useEffect } from 'react';

const isTouchDeviceByContext = ({ is_mobile, is_tablet }) =>
    is_mobile || is_tablet;

const isTouchDevice = () =>
    !!(
        typeof window !== 'undefined' &&
        ('ontouchstart' in window ||
            (window.DocumentTouch &&
                typeof document !== 'undefined' &&
                document instanceof window.DocumentTouch))
    ) ||
    !!(
        typeof navigator !== 'undefined' &&
        (navigator.maxTouchPoints || navigator.msMaxTouchPoints)
    );

const useIsTouch = (request_context) => {
    const [isTouch, updateIsTouch] = useState(
        isTouchDeviceByContext(request_context) || false
    );

    useEffect(() => {
        updateIsTouch(isTouchDevice);
    }, []);

    return isTouch;
};

export default useIsTouch;
