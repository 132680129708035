import { us, gb } from '@fiverr-private/icons/flags';
import {
    eddMowbray,
    jamesJohnson,
    jaydaLynn,
    mandyMcIntyre,
    spencerRise,
} from './assets';

export const TESTIMONIALS = [
    {
        message:
            'I tried 2 other DIY logo platforms but Fiverr gave me the best outcome and the closest design to what I envisioned. I loved the idea that there is a real designer behind the designs… not just AI.',
        rating: 5,
        author: 'Spencer Rice',
        organization: 'Kraken Code LLC',
        industry: 'Data science & Cyber services',
        countryIcon: us,
        country: 'United States',
        avatar: spencerRise,
    },
    {
        message:
            'Although we knew we wanted a building icon, it was really helpful to see some options and formats of different logo designs, to understand the right design for us. The Fiverr Logo Maker Professional package has all you need when setting up your business.',
        rating: 5,
        author: 'Jayda Lynn',
        organization: 'Diversity Funding LLC',
        industry: 'Real Estate',
        countryIcon: us,
        country: 'United States',
        avatar: jaydaLynn,
    },
    {
        message:
            "It's definitely been one of the easiest and least stressful things I've done in this process. For this quality level I expected that the price would be much higher.",
        rating: 5,
        author: 'James Johnson',
        organization: 'Author',
        industry: 'Books and Publishing',
        countryIcon: us,
        country: 'United States',
        avatar: jamesJohnson,
    },
    {
        message:
            'I wanted a logo that reflects growth, and is modern, sleek, and moving up. I really liked all the options that enable you to explore different types of logos and help you understand the design that fits your needs best.',
        rating: 4.5,
        author: 'Edd Mowbray',
        organization: 'Life coach',
        industry: 'Life Coaching',
        countryIcon: gb,
        country: 'United Kingdom',
        avatar: eddMowbray,
    },
    {
        message:
            'Logo Maker gave me the option to get a quality logo made by a real designer, but also the option to customize it until I got the exact version.',
        rating: 5,
        author: 'Mandy McIntyre',
        organization: 'Level Up Consultants',
        industry: 'Consulting',
        countryIcon: us,
        country: 'United States',
        avatar: mandyMcIntyre,
    },
];
