const getIsSSR = require('./utils/getIsSSR');

const VARIATIONS_TOOLBAR_FIELD_DEBOUNCE = 250;
const BRAND_NAME_MAXLENGTH = 40;
const SLOGAN_MAXLENGTH = 40;
const TAG_PAGE_SOURCE = 'tag_page';
const DEFAULT_LOCALE = 'en-US';
const FIELD_TYPES = {
    BRAND_NAME: 'brandName',
    SLOGAN: 'slogan',
};

const LEARN_MORE_TRACKER_POS = {
    top: 'top_page',
    mid: 'middle',
    bottom: 'bottom_page',
};

const GET_STARTED_CTA_POSITIONS = {
    TOP: 'Top',
    MIDDLE: 'Middle',
    BOTTOM: 'Bottom',
};

const LOGO_STYLE = {
    THREE_D: 'three_d',
    VINTAGE: 'vintage',
    CARTOON: 'cartoon',
    HAND_DRAWN: 'handdrawn',
    GEOMETRIC: 'geometric',
    MINIMALIST: 'minimalist',
    WATERCOLOR: 'watercolor',
};

const LOGO_TYPE = {
    MONOGRAM: 'monogram',
    MASCOT: 'mascot',
    EMBLEM: 'emblem',
    ABSTRACT: 'abstract',
    PICTORIAL: 'pictorial',
};

const PACKAGE_TYPES = {
    ESSENTIAL: 'basic',
    PREMIUM: 'premium',
    UNLIMITED: 'unlimited',
    BASIC_TO_PREMIUM_UPGRADE: 'basic_to_premium_upgrade',
    BASIC_TO_UNLIMITED_UPGRADE: 'basic_to_unlimited_upgrade',
    PREMIUM_TO_UNLIMITED_UPGRADE: 'premium_to_unlimited_upgrade',
    DISCOUNTED_BASIC_TO_PREMIUM_UPGRADE: 'discounted_basic_to_premium_upgrade',
    DISCOUNTED_BASIC_TO_UNLIMITED_UPGRADE:
        'discounted_basic_to_unlimited_upgrade',
    DISCOUNTED_PREMIUM_TO_UNLIMITED_UPGRADE:
        'discounted_premium_to_unlimited_upgrade',
    RECURRING_BASIC: 'recurring_basic',
    RECURRING_PREMIUM: 'recurring_premium',
    RECURRING_UNLIMITED: 'recurring_unlimited',
};

const UPGRADE_PACKAGE_TYPES = [
    PACKAGE_TYPES.BASIC_TO_PREMIUM_UPGRADE,
    PACKAGE_TYPES.BASIC_TO_UNLIMITED_UPGRADE,
    PACKAGE_TYPES.PREMIUM_TO_UNLIMITED_UPGRADE,
    PACKAGE_TYPES.DISCOUNTED_BASIC_TO_PREMIUM_UPGRADE,
    PACKAGE_TYPES.DISCOUNTED_BASIC_TO_UNLIMITED_UPGRADE,
    PACKAGE_TYPES.DISCOUNTED_PREMIUM_TO_UNLIMITED_UPGRADE,
];

const FEATURES = {
    NON_EDITABLE_LOGO: 'non_editable_logo',
    ONE_LOGO_REVISION: 'one_logo_revision',
    UNLIMITED_LOGO_REVISIONS: 'unlimited_logo_revisions',
    FREE_REVISIONS: 'free_revisions',
    INSTANT_DELIVERY: 'instant_delivery',
    FULL_COMMERCIAL_RIGHTS: 'full_commercial_rights',
    HIGH_RESOLUTION_PNG_FILES: 'high_resolution_png_files',
    TRANSPARENT_BACKGROUND: 'transparent_background',
    PRINT_READY: 'print_ready',
    RESIZABLE_VECTOR_FILES: 'resizable_vector_files',
    WEBSITES_AND_APP: 'websites_and_app',
    SOCIAL_MEDIA_KIT: 'social_media_kit',
    BRAND_GUIDELINES: 'brand_guidelines',
    ZOOM_BACKGROUNDS: 'zoom_backgrounds',
};

const UNLIMITED_FEATURES = [
    FEATURES.UNLIMITED_LOGO_REVISIONS,
    FEATURES.INSTANT_DELIVERY,
    FEATURES.FULL_COMMERCIAL_RIGHTS,
    FEATURES.HIGH_RESOLUTION_PNG_FILES,
    FEATURES.TRANSPARENT_BACKGROUND,
    FEATURES.PRINT_READY,
    FEATURES.RESIZABLE_VECTOR_FILES,
    FEATURES.WEBSITES_AND_APP,
    FEATURES.SOCIAL_MEDIA_KIT,
    FEATURES.BRAND_GUIDELINES,
    FEATURES.ZOOM_BACKGROUNDS,
];

const ESSENTIAL_FEATURES = [
    FEATURES.NON_EDITABLE_LOGO,
    FEATURES.INSTANT_DELIVERY,
    FEATURES.FULL_COMMERCIAL_RIGHTS,
    FEATURES.HIGH_RESOLUTION_PNG_FILES,
    FEATURES.TRANSPARENT_BACKGROUND,
];

const PREMIUM_FEATURES = [
    FEATURES.ONE_LOGO_REVISION,
    FEATURES.INSTANT_DELIVERY,
    FEATURES.FULL_COMMERCIAL_RIGHTS,
    FEATURES.HIGH_RESOLUTION_PNG_FILES,
    FEATURES.TRANSPARENT_BACKGROUND,
    FEATURES.PRINT_READY,
    FEATURES.RESIZABLE_VECTOR_FILES,
    FEATURES.WEBSITES_AND_APP,
    FEATURES.SOCIAL_MEDIA_KIT,
    FEATURES.BRAND_GUIDELINES,
    FEATURES.ZOOM_BACKGROUNDS,
];

const COOKIES = {
    LIGHTRICKS_REDIRECT: 'boostedDeepLink',
    MARKETING_TRACKING: 'refinfo',
    REDIRECT_URL: 'redirect_url',
};

const PERSEUS_API_VER = {
    V1: 'v1',
    V3: 'v3',
};

const UNAVAILABLE_FEATURES = new Set([FEATURES.NON_EDITABLE_LOGO]);

const PACKAGE_FEATURES = {
    [PACKAGE_TYPES.ESSENTIAL]: ESSENTIAL_FEATURES,
    [PACKAGE_TYPES.PREMIUM]: PREMIUM_FEATURES,
    [PACKAGE_TYPES.UNLIMITED]: UNLIMITED_FEATURES,
};

const HEADER_TYPES = {
    LOGO_MAKER: 'LogoMaker',
    LOGO_MAKER_GUEST: 'LoggedOutLogoMaker',
    LOGO_MAKER_LP: 'LogoMakerLP',
    LOGO_MAKER_GUEST_LP: 'LoggedOutLogoMakerLP',
};

const SIDEBAR_TYPES = {
    LOGO_MAKER: 'LogoMaker',
    LOGO_MAKER_GUEST: 'LogoMakerGuest',
};

const FOOTER_TYPES = {
    LOGO_MAKER: 'LogoMaker',
    LOGO_MAKER_LP: 'LogoMakerLP',
};

const SELLER_LEVELS = {
    BAD_ACTOR: 'BAD_ACTOR',
    NO_LEVEL: 'NO_LEVEL',
    LOW_QUALITY: 'LOW_QUALITY',
    NEW_SELLER: 'NEW_SELLER',
    LEVEL_ONE: 'LEVEL_ONE',
    LEVEL_TWO: 'LEVEL_TWO',
    LEVEL_TRS: 'LEVEL_TRS',
};

const HTTP_STATUS_CODE = {
    MOVED_PERMANENTLY: 301,
    FOUND: 302,
    SEE_OTHER: 303,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
};

const FIVERR_DOMAIN = 'fiverr.com';

const SAVED_LOGOS_TABS = {
    DESIGNS_TAB: 'designs',
    FAVORITES_TAB: 'favorites',
};

const IS_SSR = getIsSSR();

const BRAND_PERSONALITY_KEYS = [
    'classicOrModern',
    'accessibleOrLuxurious',
    'funOrSerious',
    'simpleOrSophisticated',
    'innovativeOrEstablished',
];

module.exports = {
    VARIATIONS_TOOLBAR_FIELD_DEBOUNCE,
    BRAND_NAME_MAXLENGTH,
    SLOGAN_MAXLENGTH,
    TAG_PAGE_SOURCE,
    DEFAULT_LOCALE,
    FIELD_TYPES,
    LEARN_MORE_TRACKER_POS,
    LOGO_STYLE,
    LOGO_TYPE,
    PACKAGE_TYPES,
    UPGRADE_PACKAGE_TYPES,
    FEATURES,
    UNAVAILABLE_FEATURES,
    ESSENTIAL_FEATURES,
    PREMIUM_FEATURES,
    COOKIES,
    PERSEUS_API_VER,
    HEADER_TYPES,
    SIDEBAR_TYPES,
    FOOTER_TYPES,
    SELLER_LEVELS,
    HTTP_STATUS_CODE,
    FIVERR_DOMAIN,
    SAVED_LOGOS_TABS,
    IS_SSR,
    GET_STARTED_CTA_POSITIONS,
    BRAND_PERSONALITY_KEYS,
    PACKAGE_FEATURES,
};
