import { useEffect } from 'react';
import topLevelSource from '../../storage/topLevelSource';

const useTopLevelSource = (source) => {
    useEffect(() => {
        source &&
            topLevelSource.setTopLevelSourceInStorage(
                topLevelSource.TOP_LEVEL_SOURCE_MAPPING[source]
            );
    }, [source]);
};

export default useTopLevelSource;
