const {
    ESSENTIAL_FEATURES,
    PREMIUM_FEATURES,
    UNLIMITED_FEATURES,
    PACKAGE_TYPES,
} = require('../../shared/constants');
const { addDomainPrefix } = require('../../shared/utils/addDomainPrefix');

const APP_NAME = 'logo_packages';
const DOMAIN_APP_NAME = addDomainPrefix(APP_NAME);

const PACKAGE_SAMPLE_LINK =
    'https://fiverr-makers.s3.amazonaws.com/Fiverr_Logo_Maker_Sample.zip';

const PACKAGES = {
    ESSENTIAL: {
        packageType: PACKAGE_TYPES.PREMIUM,
        type: PACKAGE_TYPES.ESSENTIAL,
        price: 3000,
        downloadLink:
            'https://fiverr-makers.s3.amazonaws.com/Fiverr_Basic_Kit.zip',
        features: ESSENTIAL_FEATURES,
        inTestFeatures: ESSENTIAL_FEATURES,
    },
    PREMIUM: {
        packageType: PACKAGE_TYPES.PREMIUM,
        type: PACKAGE_TYPES.PREMIUM,
        price: 6000,
        downloadLink:
            'https://fiverr-makers.s3.amazonaws.com/Fiverr_Advanced_Kit.zip',
        features: PREMIUM_FEATURES,
        inTestFeatures: PREMIUM_FEATURES,
    },
    UNLIMITED: {
        packageType: PACKAGE_TYPES.UNLIMITED,
        type: PACKAGE_TYPES.UNLIMITED,
        price: 9000,
        downloadLink:
            'https://fiverr-makers.s3.amazonaws.com/Fiverr_Advanced_Kit.zip',
        features: UNLIMITED_FEATURES,
        inTestFeatures: UNLIMITED_FEATURES,
    },
};

const DEFAULT_PACKAGES = [
    PACKAGES.ESSENTIAL,
    PACKAGES.PREMIUM,
    PACKAGES.UNLIMITED,
];

const APP_REFERRERS = {
    DIY: 'do_it_yourself',
};

const ORDER_TYPES = {
    DIY: 'DIY',
};

module.exports = {
    APP_NAME,
    DOMAIN_APP_NAME,
    APP_REFERRERS,
    ORDER_TYPES,
    DEFAULT_PACKAGES,
    PACKAGE_SAMPLE_LINK,
    PACKAGES,
};
