import { useState, useEffect } from 'react';
import { Impressionable } from '@fiverr-private/impressionable';
import { STATIC_IMPRESSION_ENRICHMENT } from '../../../service/bigquery';

const getImpression = ({ variation, enrichment = {}, type }) => {
    const { briefId, gig_impression, ...restOfEnrichment } = enrichment;

    return {
        type,
        ...STATIC_IMPRESSION_ENRICHMENT,
        makers_project: {
            id: variation.projectId,
            brief: { id: briefId },
            variation: { id: variation.id },
        },
        seller: { id: variation.seller.id },
        gig_impression,
        item_context: {
            position: variation.position,
        },
        ...restOfEnrichment,
    };
};

/**
 * Tracks Logo variation listings cards impressions
 * @param {Variation[]} variations - The listings variation
 * @param {Object} impressionEnrichment - The enrichment to apply on every impression
 * @param {Object} containerRef - Listings container ref
 * @returns {Object}
 */
const useImpressions = ({
    type,
    variations,
    containerRef,
    DOMSelector,
    DOMAttribute,
    impressionEnrichment,
}) => {
    const [impressionable, setImpressionable] = useState();
    const handleEnrich = (variation) =>
        getImpression({ variation, enrichment: impressionEnrichment, type });

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if (!impressionEnrichment) {
            return;
        }

        if (containerRef.current && !impressionable) {
            const impressionable = new Impressionable({
                DOMSelector,
                DOMAttribute,
                container: containerRef.current,
                data: variations.map((variation, index) => ({
                    ...variation,
                    position: index + 1,
                })),
                enrich: handleEnrich,
            });

            setImpressionable(impressionable);
        }

        return () => {
            if (impressionable) {
                impressionable.die();
                setImpressionable();
            }
        };
    }, [containerRef, impressionable, variations.length]);

    return [impressionable];
};

export default useImpressions;
