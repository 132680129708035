import React from 'react';
import { I18n } from '@fiverr-private/i18n-react';
import { Slider } from '@fiverr-private/orca';
import { TESTIMONIALS } from './constants';
import classes from './index.module.scss';
import TestimonialCard from './TestimonialCard';

const WhatUsersSay = () => (
    <section className={classes.wrapper}>
        <div className={classes.container}>
            <h2 className={classes.title}>
                <I18n k="makers_experience_perseus.buyer_landing_page.what_users_say.title" />
            </h2>
            <Slider
                items={TESTIMONIALS}
                Component={TestimonialCard}
                slidesToShow={1}
                sliderBreakpoints={[]}
                useDots={true}
                useArrows={true}
            />
        </div>
    </section>
);

export default WhatUsersSay;
