/**
 * Returns query parameter value from the search string
 *
 * @param string param - param name
 * @param string search - search string
 *
 * @return string
 */
const getQueryParamFromSearch = (param, search) => {
    if (search) {
        // eslint-disable-next-line prefer-template
        const re = new RegExp('(\\?|&)' + param + '=([^&]+)');
        const match = search.match(re);

        if (match) {
            return match[2];
        }
    }

    return '';
};

module.exports = getQueryParamFromSearch;
