const { CLOUDINARY_HOST } = require('../constants');

const getConfigPath = (
    { width, height, crop = 'fill', cropedFrom = 'center' } = {},
    dpi = 1
) => {
    const widthConfig = width ? `w_${width * dpi}` : '';
    const heightConfig = height ? `h_${height * dpi}` : '';
    const formatConfig = 'f_auto';
    const qualityConfig = 'q_auto';
    const cropConfig = `c_${crop}`;
    const croppedFromConfig = `g_${cropedFrom}`;
    return [
        widthConfig,
        heightConfig,
        formatConfig,
        qualityConfig,
        cropConfig,
        croppedFromConfig,
    ]
        .filter(Boolean)
        .join(',');
};

const getCloudinaryImagePath = (imagePath, options, dpi) =>
    `${CLOUDINARY_HOST}/${getConfigPath(
        options,
        dpi
    )}/v1/attachments/generic_asset/asset/${imagePath}`;

module.exports = getCloudinaryImagePath;
