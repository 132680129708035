import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import { noop } from 'lodash';
import { Button, Input, Avatar } from '@fiverr-private/fit';
import { I18n, translate } from '@fiverr-private/i18n-react';
import {
    keyPressListener,
    KEYS,
} from '@fiverr-private/variations_display/src/lib';
import useOnHeaderNewLogoLinkClick from '../../../../shared/hooks/useOnHeaderNewLogoLinkClick';
import { reportCreateNewLogoClick } from '../../../../shared/reportEvents/bigQuery';
import { AVATAR_PROPS } from '../../../../shared/reportEvents/bigQuery/reportCreateNewLogoClick/constants';
import { TRACKING_PAGE_NAMES } from '../../../../service/bigquery';
import { getLogoNameBriefStepHref } from '../../../../shared/utils/getLogoNameBriefStepHref';
import { useBriefIdContext } from '../../../../shared/components/BriefIdContext';
import TotalRating from '../../../../shared/components/LandingPages/TotalRating';
import ExampleImages from './examples';
import slidesConfig from './config';
import { SLIDE_SWITCHING_DELAY, SLIDE_APPEARING_TIME } from './constants';
import './style.scss';

const IntroHeader = ({
    onBrandNameSubmit = noop,
    rating,
    ratingsCount,
    source,
}) => {
    const [activeAssetIndex, setActiveAssetIndex] = useState(0);
    const slideConfig = slidesConfig[activeAssetIndex];
    const [exampleName, setExampleName] = useState(slideConfig.name);
    const [animating, setAnimating] = useState(true);
    const [sellerName, setSellerName] = useState(slideConfig.seller.username);
    const [sellerProfileImage, setSellerProfileImage] = useState(
        slideConfig.seller.profileImage
    );
    const inputRef = useRef();
    const wrapperRef = useRef();

    const briefId = useBriefIdContext();

    const scrollToFirstSection = () => {
        if (wrapperRef.current) {
            const { offsetTop, offsetHeight } = wrapperRef.current;
            window.scroll({
                top: offsetTop + offsetHeight,
                behavior: 'smooth',
            });
        }
    };

    useEffect(() => {
        const nextIndex = activeAssetIndex + 1;

        // Every slide contains two backgrounds
        // 1. background of prev slide - visible by default
        // 2. own background - invisible by default
        // to switch to the next slide you need
        // 1. set activeAssetIndex to the next one
        // 2. activate slide's background and image, set setAnimating to true

        const appearing = setTimeout(() => {
            // On that time the image is invisible
            setSellerName(slideConfig.seller.username);
            setSellerProfileImage(slideConfig.seller.profileImage);
            setExampleName(slideConfig.name);

            // On that time the active background is the same as prev slide has
            // Start slide's background and image appearing with transition
            setAnimating(true);
        }, SLIDE_APPEARING_TIME);

        const disappearing = setTimeout(() => {
            // Hide slide's background and image
            setAnimating(false);

            // Will set new slide but will not display any changes
            // the background by default is the same as prev slide has
            // because of that there is not visible difference until
            // the setAnimating state get true
            setActiveAssetIndex(
                slidesConfig.length > nextIndex ? nextIndex : 0
            );
        }, SLIDE_SWITCHING_DELAY);

        return () => {
            clearTimeout(appearing);
            clearTimeout(disappearing);
        };
    }, [
        activeAssetIndex,
        slideConfig.seller.username,
        slideConfig.name,
        slideConfig.seller.profileImage,
    ]);

    useEffect(() => {
        const logoMakerHeaderNewLogoLink = document.querySelector(
            '[data-click="logo-maker-header-new-logo-link"]'
        );
        const logoMakerHeaderNewLogoLinkClickHandler = () => {
            reportCreateNewLogoClick({
                pageName: TRACKING_PAGE_NAMES.BUYER_LANDING_PAGE,
                ...AVATAR_PROPS,
            });
        };

        if (logoMakerHeaderNewLogoLink) {
            logoMakerHeaderNewLogoLink.addEventListener(
                'click',
                logoMakerHeaderNewLogoLinkClickHandler
            );

            return () =>
                logoMakerHeaderNewLogoLink.removeEventListener(
                    'click',
                    logoMakerHeaderNewLogoLinkClickHandler
                );
        }
    }, []);

    useOnHeaderNewLogoLinkClick(() => {
        reportCreateNewLogoClick({
            pageName: TRACKING_PAGE_NAMES.BUYER_LANDING_PAGE,
            ...AVATAR_PROPS,
        });
    });

    const containerClass = classnames(
        'intro-header-container',
        'hero-section',
        slideConfig.name,
        { animating }
    );

    return (
        <div className={containerClass} ref={wrapperRef}>
            <div className="inner max-width-container makers-faq">
                <div className="upper">
                    <div className="content-wrapper">
                        <div className="intro-header-content start-brief">
                            <h1>
                                <I18n k="makers_experience_perseus.buyer_landing_page.header.page_title" />
                            </h1>
                            <h2>
                                <I18n k="makers_experience_perseus.buyer_landing_page.header.page_sub_title" />
                            </h2>
                            <Input
                                placeholder={translate(
                                    'makers_experience_perseus.buyer_landing_page.header.input_placeholder'
                                )}
                                inputClassName="intro-header-input"
                                name="get_started_brand_name"
                                autoComplete="off"
                                ref={inputRef}
                                onKeyPress={keyPressListener({
                                    [KEYS.ENTER]: () => {
                                        onBrandNameSubmit(
                                            inputRef.current.value
                                        );
                                        window.location.assign(
                                            getLogoNameBriefStepHref({
                                                source,
                                                brandName:
                                                    inputRef.current.value,
                                                briefId,
                                            })
                                        );
                                    },
                                })}
                                aria-label={translate(
                                    'makers_experience_perseus.buyer_landing_page.header.input_placeholder'
                                )}
                                data-lpignore="true"
                            />
                            <Button
                                className="intro-header-btn"
                                color={Button.COLORS.WHITE}
                                textColor={Button.TEXT_COLORS.DARK_GREY}
                                fill={Button.FILLS.FULL}
                                size={Button.SIZES.LARGE}
                                onClick={() => {
                                    onBrandNameSubmit(inputRef.current.value);
                                    window.location.assign(
                                        getLogoNameBriefStepHref({
                                            source,
                                            brandName: inputRef.current.value,
                                            briefId,
                                        })
                                    );
                                }}
                            >
                                <I18n k="makers_experience_perseus.general.make_your_logo" />
                            </Button>
                            <TotalRating
                                rating={rating}
                                ratingsCount={ratingsCount}
                                className="total-rating"
                            />
                        </div>
                    </div>

                    <div className="image-wrapper">
                        <ExampleImages name={exampleName} />
                        <div className="seller-wrapper">
                            <span>
                                <I18n k="makers_experience_perseus.buyer_landing_page.header.author_label" />
                            </span>
                            <Avatar
                                className="intro-header-avatar"
                                src={sellerProfileImage}
                                username={sellerName}
                            />
                            {sellerName}
                        </div>
                    </div>
                </div>

                <button
                    className="scroll-arrow"
                    aria-label="Scroll down"
                    onClick={scrollToFirstSection}
                />
            </div>
        </div>
    );
};

export default IntroHeader;
