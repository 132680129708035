import pathfinder from '@fiverr-private/pathfinder';

export const getLogoNameBriefStepHref = ({ source, brandName, briefId }) =>
    pathfinder(
        'logo_maker_brief_page_logo_maker_brief_step_name',
        { step_name: 'logo_name' },
        {
            query: {
                source,
                ...(brandName && { get_started_brand_name: brandName }),
                ...(briefId && { brief_id: briefId }),
            },
        }
    );
