import React from 'react';
import { makersExperience } from '@fiverr-private/logo_maker_analytics/dist/esm/mixpanel';
import monitor from '../../../service/bigquery';
import topLevelSource from '../../../shared/storage/topLevelSource';
import getQueryParamFromSearch from '../../../shared/lib/getQueryParamFromSearch';
import { UserAgentContext } from '../../../shared/components/UserAgentContext';
import { useOnceBeforeRender } from '../../../shared/hooks';
import BuyerLandingPage from '../components/BuyerLandingPage';
import { BriefIdContextProvider } from '../../../shared/components/BriefIdContext';

const { store: mixpanelStore } = makersExperience.buyerLandingPage;

export default function ClientBuyerLandingPage({
    userAgent,
    industriesTags,
    lastOrderId,
    savedProjectsNumber,
    savedDraftsNumber,
    reviewsStatistics,
    tracking,
    briefId,
}) {
    useOnceBeforeRender(() => {
        mixpanelStore.fromBoost.setData(tracking.makers_project.is_boost);
        mixpanelStore.pageType.setData(topLevelSource.SOURCES.LANDING_PAGE);

        monitor.initialize(tracking);

        const source =
            getQueryParamFromSearch('source', window.location.search) ||
            topLevelSource.SOURCES.LANDING_PAGE;
        // Set mark in the localStorage to mark a user as they came from the Makers landing page
        topLevelSource.setTopLevelSourceInStorage(source);
    });

    return (
        <UserAgentContext.Provider value={userAgent}>
            <BriefIdContextProvider value={briefId}>
                <BuyerLandingPage
                    industriesTags={industriesTags}
                    lastOrderId={lastOrderId}
                    savedProjectsNumber={savedProjectsNumber}
                    savedDraftsNumber={savedDraftsNumber}
                    reviewsStatistics={reviewsStatistics}
                />
            </BriefIdContextProvider>
        </UserAgentContext.Provider>
    );
}
