import monitor, { EVENT_TYPES } from '../../../../service/bigquery';

export const reportAddToFavoritesClick = ({
    projectId,
    variationId,
    type,
    pageCtxId,
    pageName,
}) => {
    monitor.track(EVENT_TYPES.ADD_VARIATION_TO_FAVORITES_CLICKED, {
        makers_project: {
            id: projectId,
            variation: {
                id: variationId,
            },
        },
        page: {
            name: pageName,
            ctx_id: pageCtxId,
            element: {
                type,
            },
        },
    });
};
