import React from 'react';
import pathfinder from '@fiverr-private/pathfinder';
import { I18n } from '@fiverr-private/i18n-react';
import { Tag } from '@fiverr-private/fit';
import { logger } from '@fiverr-private/obs';
import useIndustriesIdeas from './hooks/useIndustriesIdeas';
import classes from './index.module.scss';

export const getTagLink = (type, tag) => {
    if (!type || !tag) {
        logger.error(
            new Error(
                `Missing required params for tag pages per type: ${type} and tag: ${tag}`
            )
        );
    }

    return pathfinder('logo_maker_buyer_ideas_page', {
        type,
        tag,
    });
};

const IndustriesIdeas = ({ industries = [] }) => {
    const { cuttedIndustries } = useIndustriesIdeas({ industries });

    return (
        <section className={classes.wrapper}>
            <div className={classes.container}>
                <h2 className={classes.title}>
                    <I18n k="makers_experience_perseus.buyer_landing_page.industries.title" />
                </h2>
                <div className={classes.tagsBox}>
                    {cuttedIndustries.map(({ tagName, tagId, tagType }) => (
                        <Tag
                            key={tagId}
                            color={Tag.COLORS.GREY_1200}
                            theme={Tag.THEMES.HOLLOW}
                            size={Tag.SIZES.SMALL}
                            href={getTagLink(tagType, tagId)}
                            className={classes.tag}
                        >
                            {tagName}
                        </Tag>
                    ))}
                    <Tag
                        className={classes.moreTag}
                        size={Tag.SIZES.SMALL}
                        href={pathfinder('logo_maker_discover_logo_ideas_page')}
                    >
                        &#65122;{' '}
                        <I18n k="makers_experience_perseus.buyer_landing_page.industries.discover_more" />
                    </Tag>
                </div>
            </div>
        </section>
    );
};

export default IndustriesIdeas;
