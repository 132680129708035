import Illustration1 from './Illustration-value-1.svg';
import Illustration2 from './Illustration-value-2.svg';
import Illustration3 from './Illustration-value-3.svg';
import Illustration4 from './Illustration-value-4.svg';
import Illustration5 from './Illustration-value-5.svg';
import Illustration6 from './Illustration-value-6.svg';

export default [
    { src: Illustration1, width: 64, height: 65 },
    { src: Illustration2, width: 64, height: 37 },
    { src: Illustration3, width: 64, height: 33 },
    { src: Illustration4, width: 64, height: 65 },
    { src: Illustration5, width: 64, height: 65 },
    { src: Illustration6, width: 64, height: 65 },
];
