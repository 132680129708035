import { LISTING_SOURCE } from '@fiverr-private/variations_display/src';

export const KEY = 'top_level_source';

export const SOURCES = {
    LANDING_PAGE: 'landing_page',
    TAG_PAGE: 'tag_page',
    MARKETPLACE: 'marketplace',
    MARKETPLACE_FORK: 'marketplace_fork',
    LIGHTRICKS: 'lightricks',
    LOHP_BANNER: 'LOHP_banner',
};

export const TOP_LEVEL_SOURCE_MAPPING = {
    [LISTING_SOURCE.MARKETPLACE]: SOURCES.MARKETPLACE,
    [LISTING_SOURCE.MP_FORK]: SOURCES.MARKETPLACE_FORK,
};

export const BOOSTED_DEEP_LINK_REGEX = /boostedDeepLink/;
