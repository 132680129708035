import { useCallback, useEffect } from 'react';
import { throttle } from 'lodash';

/**
 * A wrapper for scroll event listener.
 */
const usePageScroll = (listener) => {
    const handleScroll = useCallback(throttle(listener, 1000 / 60), []);

    useEffect(() => {
        handleScroll();
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
};

export default usePageScroll;
