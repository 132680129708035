import React from 'react';
import { I18n, translate } from '@fiverr-private/i18n-react';
import VimeoVideo from '../../../VimeoVideo';
import {
    TRANSLATION_TITLE_KEY,
    VIDEO_SRC,
    VIDEO_THUMBNAIL,
    PREVIEW_IMG_SIZE,
} from './constants';
import classes from './index.module.scss';

const HowToMakeLogoVideo = () => (
    <section className={classes.wrapper}>
        <div className={classes.container}>
            <h2 className={classes.title}>
                <I18n k={TRANSLATION_TITLE_KEY} />
            </h2>
            <VimeoVideo
                video={VIDEO_SRC}
                preview={VIDEO_THUMBNAIL}
                previewSize={PREVIEW_IMG_SIZE}
                previewAlt={translate(
                    'makers_experience_perseus.buyer_landing_page.how_to_make_logo_video.thumbnail_alt'
                )}
            />
        </div>
    </section>
);

export default HowToMakeLogoVideo;
