import { Monitor } from './monitor';

export {
    EVENTS,
    EVENT_TYPES,
    TRACKING_PAGE_NAMES,
    STATIC_IMPRESSION_ENRICHMENT,
    SOURCE_TO_TRACKING_PAGE_NAME_MAP,
} from './monitor/constants';
export default new Monitor();
