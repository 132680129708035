import React from 'react';
import { I18n, translate } from '@fiverr-private/i18n-react';
import CloudinaryResponsiveImage from '../../../CloudinaryResponsiveImage';
import useGetInspiredByLogos from './hooks/useGetInspiredByLogos';
import classes from './index.module.scss';

const GetInspiredByLogos = () => {
    const { cuttedLogos } = useGetInspiredByLogos();

    return (
        <section className={classes.wrapper}>
            <div className={classes.container}>
                <h2 className={classes.title}>
                    <I18n k="makers_experience_perseus.buyer_landing_page.get_inspired.section_title" />
                </h2>
                <div className={classes.logos}>
                    {cuttedLogos.map(({ image, author, alt, size }, i) => (
                        <div className={classes.logoCard} key={i}>
                            <CloudinaryResponsiveImage
                                className={classes.logoImage}
                                image={image}
                                width={size.width}
                                height={size.height}
                                alt={translate(alt)}
                            />
                            <div className={classes.byBlock}>
                                <I18n k="makers_experience_perseus.buyer_landing_page.get_inspired.by" />
                                <span className={classes.author}>
                                    {' '}
                                    {author}
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default GetInspiredByLogos;
