import useEffectOnce from '../useEffectOnce';

const useIntersectionObserver = (observableRef, callback, options) => {
    useEffectOnce(() => {
        if (
            !window ||
            !window.IntersectionObserver ||
            typeof window.IntersectionObserver !== 'function'
        ) {
            return;
        }

        const observer = new IntersectionObserver(([entry]) => {
            callback(entry);
            if (entry.isIntersecting && options?.once) {
                observer.disconnect();
            }
        }, options);

        if (observableRef.current) {
            observer.observe(observableRef.current);
        }

        return () => observer.disconnect();
    });
};

export default useIntersectionObserver;
