const ILLUSTRATIONS_SIZE = {
    width: 638,
    height: 472,
};

const ILLUSTRATIONS = [
    {
        src: '9fb705ffb84c6191cceba642e31209ca-1652446427013/tell-us-about-your-business-fiverr-logo-maker.png',
        size: ILLUSTRATIONS_SIZE,
    },
    {
        src: 'b7a8792226ea84395b5ef4bb394f5885-1652446314203/define-look-and-feel-fiverr-logo-maker.png',
        size: ILLUSTRATIONS_SIZE,
    },
    {
        src: 'e156da4ce9acc8be7268c5303da0bb1a-1652445182193/generate-logo-designs-fiverr-logo-maker.png',
        size: ILLUSTRATIONS_SIZE,
    },
    {
        src: 'f9178d90ba449c24ef8bf9fa1cbf3ace-1652446458279/logo-design-editing-fiverr-logo-maker.png',
        size: ILLUSTRATIONS_SIZE,
    },
    {
        src: '3f304d8b92ed17a02cf0f4f8c50ab602-1652446375249/download-your-logo-fiverr-logo-maker.png',
        size: ILLUSTRATIONS_SIZE,
    },
];

const COPIES_SLUG = `makers_experience_perseus.buyer_landing_page.how_does_it_work`;

export const STEPS = ILLUSTRATIONS.map((illustration, i) => ({
    step: `${COPIES_SLUG}.step_${i + 1}.step`,
    title: `${COPIES_SLUG}.step_${i + 1}.title`,
    description: [`${COPIES_SLUG}.step_${i + 1}.description`],
    alt: `${COPIES_SLUG}.step_${i + 1}.alt`,
    illustration,
}));

export const INTERVAL = 6000;
