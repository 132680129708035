const {
    APP_NAME: BRIEF_PAGE_APP_NAME,
} = require('../../../apps/brief/constants');
const {
    APP_NAME: BUYER_PAGE_APP_NAME,
} = require('../../../apps/buyer/constants');
const {
    APP_NAME: BUYER_LANDING_PAGE_APP_NAME,
} = require('../../../apps/buyer_landing_page/constants');
const {
    APP_NAME: CHOOSE_VARIATION_PAGE_APP_NAME,
} = require('../../../apps/choose_variation/constants');
const {
    APP_NAME: LOGO_PACKAGES_PAGE_APP_NAME,
} = require('../../../apps/logo_packages/constants');
const {
    APP_NAME: SAVED_LOGOS_PAGE_APP_NAME,
} = require('../../../apps/buyer_saved_logos/constants');

const TRACKING_PAGE_NAMES = {
    SELLER_LANDING_PAGE: 'logo_maker_seller_landing_page',
    BUYER_LANDING_PAGE: 'logo_maker_buyer_landing_page',
    BUYER_LISTINGS: 'logo_maker_listing',
    CHOOSE_VARIATION: 'logo_maker_choose_variation_page',
    LOGO_PACKAGES: 'logo_maker_packages_page',
    BUYER_BRIEF: 'logo_maker_buyer_brief',
    SAVED_LOGOS: 'logo_maker_saved_logos',
    POST_ORDER_PAGE: 'logo_maker_post_order_page',
};

const SOURCE_TO_TRACKING_PAGE_NAME_MAP = {
    [BUYER_LANDING_PAGE_APP_NAME]: TRACKING_PAGE_NAMES.BUYER_LANDING_PAGE,
    [CHOOSE_VARIATION_PAGE_APP_NAME]: TRACKING_PAGE_NAMES.CHOOSE_VARIATION,
    [LOGO_PACKAGES_PAGE_APP_NAME]: TRACKING_PAGE_NAMES.LOGO_PACKAGES,
    [SAVED_LOGOS_PAGE_APP_NAME]: TRACKING_PAGE_NAMES.SAVED_LOGOS,
    [BRIEF_PAGE_APP_NAME]: TRACKING_PAGE_NAMES.BUYER_BRIEF,
    [BUYER_PAGE_APP_NAME]: TRACKING_PAGE_NAMES.BUYER_LISTINGS,
    buyer_editor: 'logo_maker_buyer_editor_page',
};

/**
 * Tracker supported actions
 * @type {{CLICK: string, CHANGE: string}}
 */
const TRACK_ACTIONS = {
    CLICK: 'click',
    CHANGE: 'element_changed',
};

/**
 * The tracker service static context enrichment, Will be sent on any reported event.
 * @type {{feature: {name: string, parent_name: string}, publisher: {domain: string, sub_domain: string}, out_of_session: boolean}}
 */
const TRACKING_STATIC_CONTEXT = {
    feature: {
        name: 'logo_maker',
        parent_name: 'makers_project',
    },
    publisher: {
        domain: 'buyers',
        sub_domain: 'spiders',
    },
    out_of_session: false,
};

/**
 * The supported event types.
 */
const EVENT_TYPES = {
    GET_STARTED_SELLER_CLICKED: 'getStartedSellerClicked',
    GET_STARTED_BUYER_CLICKED: 'getStartedBuyerClicked',
    IDEA_RELATED_TAG_CLICKED: 'clicked_on_logo_maker_related_tag',
    BRIEF_SUBMITTED: 'briefSubmitted',
    PAGE_VIEW: 'pageView',
    LISTINGS_REFINE_BRIEF_CLICKED: 'listingsRefineBriefClicked',
    LISTINGS_EXPLORE_MARKETPLACE_CLICKED: 'listingsExploreMarketplaceClicked',
    LISTINGS_VARIATION_CARD_CLICKED: 'listingsVariationCardClicked',
    LISTINGS_REGISTRATION_POPUP: 'listingsRegistrationPopup',
    LISTINGS_BRIEF_METADATA_UPDATED: 'listingsBriefMetadataUpdated',
    LISTINGS_CLICKED_ON_VARIATION_PREVIEW: 'listingsClickedOnVariationPreview',
    LISTINGS_HOVERED_ON_VARIATION_PREVIEW: 'listingsHoveredOnVariationPreview',
    CHOOSE_VARIATION_CLICKED_ON_VARIATION: 'chooseVariationClickedOnVariation',
    CHOOSE_VARIATION_REGISTRATION_POPUP: 'chooseVariationRegistrationPopup',
    ADD_VARIATION_TO_FAVORITES_CLICKED: 'addVariationToFavoritesClicked',
    CREATE_NEW_LOGO_CLICK: 'createNewLogoClick',
    TAG_REMOVED: 'tagRemoved',
};

/**
 * The supported event groups
 */
const EVENTS_GROUPS = {
    USER_ACTION: {
        group: 'user_action',
        sub_group: 'website_action',
    },
    TECH_EVENTS: {
        group: 'technical_events',
        sub_group: 'feature_events',
    },
};

/**
 * The tracking Event object
 * @typedef {Object} TrackerEvent
 * @property {String} group - The event group
 * @property {String} sub_group - The event sub group
 * @property {Object} action - Object describing the action occurred
 * @property {Object} page - Object the page in general, Contain data regarding it's triggered element
 */
const EVENTS = {
    [EVENT_TYPES.PAGE_VIEW]: {
        group: 'page_view',
        sub_group: 'website_action',
    },
    [EVENT_TYPES.IDEA_RELATED_TAG_CLICKED]: {
        type: 'clicked_on_logo_maker_related_tag',
        action: { type: TRACK_ACTIONS.CLICK },
        ...EVENTS_GROUPS.USER_ACTION,
    },
    [EVENT_TYPES.GET_STARTED_SELLER_CLICKED]: {
        type: 'clicked_on_logo_maker_seller_get_started',
        action: { type: TRACK_ACTIONS.CLICK },
        page: {
            name: TRACKING_PAGE_NAMES.SELLER_LANDING_PAGE,
        },
        ...EVENTS_GROUPS.USER_ACTION,
    },
    [EVENT_TYPES.GET_STARTED_BUYER_CLICKED]: {
        type: 'clicked_on_logo_maker_buyer_get_started',
        action: { type: TRACK_ACTIONS.CLICK },
        page: {
            name: TRACKING_PAGE_NAMES.BUYER_LANDING_PAGE,
        },
        ...EVENTS_GROUPS.USER_ACTION,
    },
    [EVENT_TYPES.BRIEF_SUBMITTED]: {
        type: 'logo_maker_brief.submitted',
        ...EVENTS_GROUPS.TECH_EVENTS,
    },
    [EVENT_TYPES.LISTINGS_REFINE_BRIEF_CLICKED]: {
        type: 'clicked_on_logo_maker_edit_your_brief',
        action: { type: TRACK_ACTIONS.CLICK },
        page: {
            name: TRACKING_PAGE_NAMES.BUYER_LISTINGS,
            element: {
                text: 'edit your brief',
            },
        },
        ...EVENTS_GROUPS.USER_ACTION,
    },
    [EVENT_TYPES.LISTINGS_EXPLORE_MARKETPLACE_CLICKED]: {
        type: 'clicked_on_logo_maker_explore_logo_designers',
        action: { type: TRACK_ACTIONS.CLICK },
        page: {
            name: TRACKING_PAGE_NAMES.BUYER_LISTINGS,
            element: {
                text: 'explore logo designers',
            },
        },
        ...EVENTS_GROUPS.USER_ACTION,
    },
    [EVENT_TYPES.CHOOSE_VARIATION_CLICKED_ON_VARIATION]: {
        type: 'clicked_on_logo_maker_variation_in_choose_variation',
        action: { type: TRACK_ACTIONS.CLICK },
        page: {
            name: TRACKING_PAGE_NAMES.CHOOSE_VARIATION,
        },
        ...EVENTS_GROUPS.USER_ACTION,
    },
    [EVENT_TYPES.LISTINGS_CLICKED_ON_VARIATION_PREVIEW]: {
        type: 'clicked_on_variation_from_logo_card_gallery',
        action: { type: TRACK_ACTIONS.CLICK },
        page: {
            name: TRACKING_PAGE_NAMES.BUYER_LISTINGS,
        },
        ...EVENTS_GROUPS.USER_ACTION,
    },
    [EVENT_TYPES.LISTINGS_HOVERED_ON_VARIATION_PREVIEW]: {
        type: 'logo_card_is_hovered',
        action: { type: TRACK_ACTIONS.CLICK },
        page: {
            name: TRACKING_PAGE_NAMES.BUYER_LISTINGS,
        },
        ...EVENTS_GROUPS.USER_ACTION,
    },
    [EVENT_TYPES.LISTINGS_VARIATION_CARD_CLICKED]: {
        type: 'clicked_on_logo_maker_variation_card',
        action: { type: TRACK_ACTIONS.CLICK },
        page: {
            name: TRACKING_PAGE_NAMES.BUYER_LISTINGS,
        },
        ...EVENTS_GROUPS.USER_ACTION,
    },
    [EVENT_TYPES.LISTINGS_BRIEF_METADATA_UPDATED]: {
        type: 'logo_maker_brief_name_slogan.updated',
        action: { type: TRACK_ACTIONS.CHANGE },
        page: {
            name: TRACKING_PAGE_NAMES.BUYER_LISTINGS,
        },
        ...EVENTS_GROUPS.USER_ACTION,
    },
    [EVENT_TYPES.ADD_VARIATION_TO_FAVORITES_CLICKED]: {
        type: 'clicked_on_logo_maker_save_variation',
        action: { type: TRACK_ACTIONS.CLICK },
        ...EVENTS_GROUPS.USER_ACTION,
    },
    [EVENT_TYPES.CREATE_NEW_LOGO_CLICK]: {
        type: 'clicked_on_logo_maker_create_new_logo',
        action: { type: TRACK_ACTIONS.CLICK },
        ...EVENTS_GROUPS.USER_ACTION,
    },
    [EVENT_TYPES.TAG_REMOVED]: {
        type: 'logo_maker_tag.not_exist',
        reason: 'tag_removed',
        entity: 'business',
        action: { type: TRACK_ACTIONS.CLICK },
        ...EVENTS_GROUPS.TECH_EVENTS,
        ...TRACKING_STATIC_CONTEXT,
    },
};

/**
 * Events that are created & reported by other services, but require makers enrichment.
 * @type {Object}
 */
const ENRICHABLE_EVENTS = {
    [EVENT_TYPES.LISTINGS_REGISTRATION_POPUP]: {
        page: {
            name: TRACKING_PAGE_NAMES.BUYER_LISTINGS,
        },
    },
};

/**
 * The static logo card static impression enrichment
 */
const STATIC_IMPRESSION_ENRICHMENT = {
    group: 'users_impressions',
    sub_group: 'content_impression',
    action: { type: 'impression' },
    ...TRACKING_STATIC_CONTEXT,
};

module.exports = {
    TRACKING_PAGE_NAMES,
    SOURCE_TO_TRACKING_PAGE_NAME_MAP,
    TRACKING_STATIC_CONTEXT,
    EVENT_TYPES,
    EVENTS,
    ENRICHABLE_EVENTS,
    STATIC_IMPRESSION_ENRICHMENT,
};
