import { localStorage } from '@fiverr-private/futile/lib/storage';
import {
    KEY,
    SOURCES,
    BOOSTED_DEEP_LINK_REGEX,
    TOP_LEVEL_SOURCE_MAPPING,
} from './constants';

/**
 * Get user top level source stored in storage
 * @returns {SOURCES}
 */
const getTopLevelSourceFromStorage = () => localStorage.get(KEY);

/**
 * Clear user top level source stored in storage
 * @returns {SOURCES}
 */
const clearTopLevelSourceFromStorage = () => localStorage.remove(KEY);

/**
 * Set user top level source in storage.
 * @param source
 * @returns {Boolean}
 */
const setTopLevelSourceInStorage = (source) => {
    if (!getTopLevelSourceFromStorage()) {
        localStorage.set(KEY, source);

        return true;
    }

    return false;
};

export default {
    getTopLevelSourceFromStorage,
    setTopLevelSourceInStorage,
    clearTopLevelSourceFromStorage,
    SOURCES,
    BOOSTED_DEEP_LINK_REGEX,
    TOP_LEVEL_SOURCE_MAPPING,
};
