import React from 'react';

const Quotes = ({ className }) => (
    <svg
        className={className}
        width="117"
        height="78"
        viewBox="0 0 117 78"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M71.6268 42.6801C72.7683 22.864 91.8878 7.29411 116.429 5.02942L117 0.5C85.6098 1.63236 56.5024 22.2978 56.5024 52.3051C56.5024 66.4596 64.2073 77.5 78.1902 77.5C90.7463 77.5 100.449 68.4412 100.449 55.9853C100.449 46.6434 93.8854 39.2831 83.6122 39.2831C79.0463 39.2831 75.0512 40.4155 71.6268 42.6801ZM15.1244 42.6801C16.2659 22.864 35.3854 7.29411 59.9268 5.02942L60.4976 0.5C29.1073 1.63236 0 22.2978 0 52.3051C0 66.4596 7.99024 77.5 21.6878 77.5C34.5293 77.5 43.9463 68.4412 43.9463 55.9853C43.9463 46.6434 37.6683 39.2831 27.3951 39.2831C22.5439 39.2831 18.5488 40.4155 15.1244 42.6801Z"
            fill="#EFEFF0"
        />
    </svg>
);

export default Quotes;
