import { range } from 'lodash';

const PREFIX =
    'makers_experience_perseus.buyer_landing_page.what_makes_good_logo';
const POINTS_AMOUNT = 6;

export const CONTENT = range(POINTS_AMOUNT).map((_, i) => ({
    title: `${PREFIX}.point_${i + 1}.title`,
    body: `${PREFIX}.point_${i + 1}.body`,
}));
