import React from 'react';
import { I18n } from '@fiverr-private/i18n-react';
import { Collapsible } from '@fiverr-private/fit';
import serverRenderIf from '../../../../utils/serverRenderIf';
import { useUserAgentContext } from '../../../UserAgentContext';
import Title from './Title';
import { CONTENT } from './constants';
import classes from './index.module.scss';

const WhatMakesGoodLogo = () => {
    const { isMobile } = useUserAgentContext();

    return (
        <section className={classes.wrapper}>
            <div className={classes.thinContainer}>
                <h2 className={classes.title}>
                    <I18n k="makers_experience_perseus.buyer_landing_page.what_makes_good_logo.section_title" />
                </h2>
                <p className={classes.subtitle}>
                    <I18n k="makers_experience_perseus.buyer_landing_page.what_makes_good_logo.section_subtitle" />
                </p>
            </div>
            <div className={classes.container}>
                {serverRenderIf(isMobile) && (
                    <div className={classes.collapsedView}>
                        {CONTENT.map(({ title, body }, i) => (
                            <Collapsible
                                key={title}
                                className={classes.collapsible}
                                titleWrapperClassName={
                                    classes.collapsibleTitleWrapper
                                }
                                title={<Title text={title} index={i + 1} />}
                            >
                                <p>
                                    <I18n k={body} />
                                </p>
                            </Collapsible>
                        ))}
                    </div>
                )}
                {serverRenderIf(!isMobile) && (
                    <div className={classes.nonCollapsedView}>
                        {CONTENT.map(({ title, body }, i) => (
                            <div key={title}>
                                <Title text={title} index={i + 1} />
                                <p>
                                    <I18n k={body} />
                                </p>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </section>
    );
};

export default WhatMakesGoodLogo;
