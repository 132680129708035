import monitor, {
    EVENT_TYPES,
    TRACKING_PAGE_NAMES,
} from '../../../../service/bigquery';

export const reportCreateNewLogoClick = ({
    position,
    elementName,
    pageName = TRACKING_PAGE_NAMES.SAVED_LOGOS,
    component,
}) => {
    monitor.track(EVENT_TYPES.CREATE_NEW_LOGO_CLICK, {
        page: {
            component: {
                name: component,
            },
            name: pageName,
            element: {
                position,
                name: elementName,
            },
        },
    });
};
